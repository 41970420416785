import React from 'react';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, DialogActions, Grid } from '@material-ui/core';

import { Form } from '../Common/styled/Form';
import { TextFieldUI } from '../../componentsUI/TextField';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { TextAreaUI } from '../../componentsUI/TextArea';
import { SwitcherUI } from '../../componentsUI/SwitcherUI';
import useStyles from '../../components/Case/styled/CaseFormMakeStyles';
import Loading from '../Common/Loading';

export const StudyInfoForm = ({
  formId,
  initialValues,
  isHospitalStudy,
  buttons,
  submitting,
  submitAction,
  serverError,
  setServerError,
  showServerError,
  manageRestriction = false,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('required.field')),
    description: Yup.string().required(t('required.field')),
    restricted: Yup.bool(),
  });

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      await submitAction(values);
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form autoComplete="off" id={formId} onSubmit={props.handleSubmit}>
            <Grid container spacing={2} className={styles.form}>
              <Grid item xs={12}>
                <TextFieldUI label={t('title')} name="title" props={props} />
              </Grid>
              <Grid item xs={12}>
                <TextAreaUI label={t('description')} name="description" props={props} />
              </Grid>
              {isHospitalStudy && manageRestriction && (
                <Grid item xs={12}>
                  <SwitcherUI name="restricted" label={t('access.restricted')} props={props} />
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      {submitting && <Loading />}
      {!!serverError && showServerError()}
      <DialogActions className={styles.actions}>
        {!!buttons && buttons.map((item, index) => (
          <Button
            variant={item.variant}
            className={item.classes}
            key={index.toString()}
            disabled={submitting}
            color={item.color}
            onClick={item.onClick}
            type={item.type && item.type}
            form={item.form && item.form}
          >
            {item.label}
          </Button>
        ))}
      </DialogActions>
    </>
  );
};
