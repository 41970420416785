import React from 'react';

import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import moment from 'moment-timezone';

import {
  CancelRounded,
  CheckCircle,
  CloudDownload,
  Delete,
  Description,
  ErrorOutline,
  HighlightOffRounded,
  HourglassEmpty, OpenInNew,
} from '@material-ui/icons';
import { Divider, Grid, makeStyles } from '@material-ui/core';

import { NoFilesWrapper } from '../../Common/FileCard/styled/NoFilesWrapper';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { getFormattedDateTime } from '../../../utils/dateTimeUtils';
import { IconButtonUI } from '../../../componentsUI/IconButton';
import { getAdvancedToolStatusLabel } from '../../../utils/advancedToolUtils';

const useStyles = makeStyles((theme) => ({
  row: {
    marginLeft: -18,
    width: 'calc(100% + 36px)',
    backgroundColor: '#f3f3f3',
    borderBottom: '1px solid #ddd',
    transition: 'background-color .35s',
    '& .MuiGrid-item': {
      height: 42,
      display: 'flex',
      alignItems: 'center',
    },
    '& .buttons': {
      opacity: 0,
      transition: 'opacity .35s',
    },
    '&:hover': {
      backgroundColor: '#efefef',
      transition: 'background-color .35s',
      '& .buttons': {
        opacity: 1,
        transition: 'opacity .35s',
      },
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '1.75em',
      '&.icon-small': {
        fontSize: '1.375em',
      },
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    fontSize: '.9375em',
    fontWeight: 700,
    color: theme.palette.grey.A200,
  },
  date: {
    fontWeight: 500,
    fontSize: '.8em',
    color: theme.palette.grey.A700,
  },
  items: {
    justifyContent: 'flex-end',
    '& .divider': {
      height: 18,
      margin: '0 2px',
    },
  },
}));

const ReportButtonType = ({ item, allowed }) => {
  switch (item.type) {
    case 'divider':
      return (
        <Divider key={item.name} className="buttons divider" orientation="vertical" />
      );
    default:
      return (
        <div className="buttons">
          <IconButtonUI
            key={item.key}
            Icon={item.icon}
            title={item.name}
            disabled={allowed !== undefined ? !item.disabled && !allowed : item.disabled}
            handleClick={item.handleClick}
          />
        </div>
      );
  }
};

export const AdvancedToolReportFileCard = ({ report, tool, study, setDeleteReportData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();

  const formattedCreatedOn = report.createdAt && moment(report.createdAt).utc().format('DD[/]MM[/]YYYY');

  const downloadPDF = (event) => {
    event.stopPropagation();
    fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/media/report/${report.uuid}/download`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }).then((res) => res.blob().then((blob) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `alma3d_report_${report.uuid}_${formattedCreatedOn}`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }));
  };

  const openFeedbackURL = (event) => {
    event.stopPropagation();
    window.open(`${tool.feedbackURL}${study.studyInstanceUid}`, '_blank');
  };

  const info = {
    uuid: report.uuid,
    tool: tool.name,
    date: report.createdAt,
  };

  const downloadReport = (event) => downloadPDF(event);
  const toggleDeleteModal = () => (setDeleteReportData(info));

  const openReportWindow = tool.feedbackURL
    ? [{ name: t('view.tool.report'), icon: OpenInNew, handleClick: openFeedbackURL }]
    : [];

  const buttons = {
    completed: [
      { name: t('download.report'), icon: CloudDownload, handleClick: downloadReport },
      { name: 'divider2', type: 'divider' },
      { name: t('delete.report'), icon: Delete, handleClick: toggleDeleteModal },
    ],
    pending: [
      ...(openReportWindow),
      { name: t('cancel.report'), icon: CancelRounded, handleClick: toggleDeleteModal },
    ],
    open_iframe: [
      ...(openReportWindow),
      { name: t('remove.request.log'), icon: HighlightOffRounded, handleClick: toggleDeleteModal },
    ],
    error: [
      { name: t('remove.report.log'), icon: HighlightOffRounded, handleClick: toggleDeleteModal },
    ],
  };

  const items = report && report.status && buttons[report.status.toLowerCase()];

  return (
    <Grid container key={report.key} className={classes.row}>
      <Grid item xs={1} className={classes.iconWrapper}>
        {report.status === 'COMPLETED' && (<Description color="primary" />)}
        {report.status === 'PENDING' && (<HourglassEmpty color="disabled" className="icon-small" />)}
        {report.status === 'ERROR' && (<ErrorOutline color="disabled" className="icon-small" />)}
        {report.status === 'OPEN_IFRAME' && (<CheckCircle color="disabled" className="icon-small" />)}
      </Grid>
      <Grid item xs className={classes.info}>
        <p className={classes.status}>
          {t(getAdvancedToolStatusLabel(report.status)).toUpperCase()}
          <br />
          <span className={classes.date}>
            {getFormattedDateTime({ date: report.createdAt })}
          </span>
        </p>
      </Grid>
      <Grid item xs={3} className={classes.items}>
        {!!items && items.map((item, index) => (
          <ReportButtonType key={index.toString()} item={item} />
        ))}
      </Grid>
    </Grid>
  );
};

export const AdvancedToolNoReports = () => {
  const { t } = useTranslation();

  return (
    <NoFilesWrapper>
      <GreyLabel>{t('advanced.tool.no.reports')}</GreyLabel>
    </NoFilesWrapper>
  );
};
