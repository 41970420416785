import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import React from 'react';
import { sizes } from '../../../styles/variables';

import { Heading } from '../../Common/styled/Heading';
import { ExtraBold } from '../../Common/styled/Text';
import { Column, Row } from '../../Common/styled/Groups';
import { Spacer } from '../../Common/styled/Spacer';
import { Card } from '../../Common/styled/Card';

export const StudyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 10%;

  ${ExtraBold} {
    font-size: ${(props) => props.theme.font.size.large};
  }

  ${Card} {
    background-color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: ${sizes.mdMax}) {
    flex-direction: column-reverse;

    ${ExtraBold} {
      font-size: ${(props) => props.theme.font.size.semilarge};
    }
  }
`;

export const StudyDetailsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(props) => props.theme.cardPadding};
  padding-left: 0;
  padding-right: 0;

  @media (max-width: ${sizes.xsMax}) {
    flex-direction: column;
    margin-bottom: 1%;
  }
`;

export const StudyTitle = styled.div`
  ${Heading} {
    margin-top: 0;
  }

  @media (min-width: ${sizes.smMin}) {
    width: 50%;
  }
`;

export const StudyOwner = styled.div``;

export const GenericDetailsWrapper = styled.div`
  padding: ${(props) => props.theme.cardPadding};

  @media screen and (max-width: ${sizes.xsMax}) {
    padding: 4%;

    ${Spacer} {
      margin: 0;
    }

    ${Column} {
      margin-bottom: 5%;
    }
  }
`;

export const StudySeriesWrapper = styled.div`
  padding: ${(props) => props.theme.cardPadding};
  border-top: ${(props) => props.theme.cardBorder};

  @media screen and (max-width: ${sizes.xsMax}) {
    padding: 4%;

    ${Row} {
      flex-direction: row;
    }
  }
`;

export const StyledStudyDetailsDicom = styled.div`
  ${Card} {
    width: 100%;
    box-shadow: 2px 2px 6px 1px #00000015;
  }
`;

export const DicomStudyDetails = ({ classes, children }) => (
  <Paper elevation={2} className={classes}>
    {children}
  </Paper>
);
