import React from 'react';

import { useTranslation } from 'react-i18next';
// import { isMobile, isTablet } from 'react-device-detect';

import { NoReportsWrapper } from '../../Common/AdvancedToolReportsCard/styled/NoReportsWrapper';
import AdvancedToolReportHeaderDetails from './AdvancedToolReportHeaderDetails';
import { AdvancedToolReportFileCard, AdvancedToolNoReports } from './AdvancedToolReportFileCard';
import SectionAccordion from '../../../componentsUI/SectionAccordion';

const displayReportOptions = ['ERROR', 'PENDING', 'OPEN_IFRAME', 'COMPLETED'];

export const AdvancedToolReportsCard = ({ advancedTool, setDeleteReportData, study }) => {
  // const isHandheld = isMobile || isTablet;

  const AccordionHeader = () => (<AdvancedToolReportHeaderDetails advancedTool={advancedTool} />);

  const AdvancedToolStudyReports = () => (
    !!advancedTool.reports && advancedTool.reports.length
      ? advancedTool.reports.map((report) => displayReportOptions.includes(report.status)
      && (
        <AdvancedToolReportFileCard
          key={report.uuid}
          report={report}
          tool={advancedTool}
          study={study}
          setDeleteReportData={setDeleteReportData}
        />
      ))
      : <AdvancedToolNoReports />
  );

  return <SectionAccordion title="plan.usage" Item={AdvancedToolStudyReports} Header={AccordionHeader} />;
};

export const NoPlugins = () => {
  const { t } = useTranslation();

  return (
    <NoReportsWrapper>
      <span>{t('study.no.reports')}</span>
    </NoReportsWrapper>
  );
};

export default AdvancedToolReportsCard;
