import React, { useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { AdvancedToolReportsCard, NoPlugins } from './report/AdvancedToolReportsCard';
import { DeleteReportDialog } from './modal/DeleteReportDialog';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.75em 0',
  },
  box: {
    width: '100%',
    flexGrow: 1,
    padding: '0 .5em',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  subheading: {
    margin: '.375em 1em .75em',
    fontSize: '1.125em',
    fontWeight: 700,
  },
  list: {
    '& .MuiAccordionDetails-root > .MuiGrid-container:first-child': {
      borderTop: '1px solid #ddd',
    },
  },
  attr: {
    color: '#aaa',
  },
  details: {
    marginTop: '.75em',
    fontSize: '.9375em',
    '& p': {
      margin: '.125em .375em 0',
    },
    '& p.first': {
      marginTop: '.625em',
    },
  },
}));

export const StudyDetailsReportsCard = ({ study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [deleteReportData, setDeleteReportData] = useState(null);
  const closeDeleteReportModal = () => setDeleteReportData(null);
  const openDeleteReportModal = (data) => {
    setDeleteReportData(data);
  };

  // Sort study reports by advancedTool provider
  study.reports.sort((a, b) => a.advancedTool.provider.name.localeCompare(b.advancedTool.provider.name));

  // Group reports by advanced tool
  const advancedTools = [];
  study.reports.forEach((report) => {
    const advancedTool = advancedTools.find((elem) => elem.uuid === report.advancedTool.uuid);
    if (advancedTool) advancedTool.reports.push({ uuid: report.uuid, status: report.status, file: report.file, createdAt: report.createdAt });
    else advancedTools.push({ ...report.advancedTool, reports: [{ uuid: report.uuid, status: report.status, file: report.file, createdAt: report.createdAt }] });
  });

  return (
    <Grid container>
      <Grid item xs={12} className={classes.subheading}>
        {t('study.reports')}
      </Grid>
      <Grid item xs={12} className={classes.list}>
        {!!advancedTools && !!advancedTools.length
          ? (
            <>
              <DeleteReportDialog
                open={!!deleteReportData}
                study={study}
                report={deleteReportData}
                onClose={closeDeleteReportModal}
              />
              {advancedTools.map((advancedTool) => (
                <AdvancedToolReportsCard
                  key={advancedTool.uuid}
                  advancedTool={advancedTool}
                  setDeleteReportData={openDeleteReportModal}
                  study={study}
                />
              ))}
            </>
          ) : <NoPlugins />}
      </Grid>
    </Grid>
  );
};

export default StudyDetailsReportsCard;
