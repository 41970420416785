import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from 'react-keycloak';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { ExtraBold, Span } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import useStyles from '../styled/DeleteStudyDialogStyles';
import { RejectDicomStudy } from '../../../utils/DicomFileUtils';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const DeletePacsStudyDialog = ({ open, onClose, study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [keycloak] = useKeycloak();
  const [serverError, setServerError] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);

  const handleClose = () => {
    setServerError(false);
    setDisabledButtons(false);
    onClose();
  };

  const handleSubmit = async () => {
    setServerError(false);
    setDisabledButtons(true);
    try {
      await RejectDicomStudy(study, keycloak)
        .then((res) => {
          if (res.status === 200) {
            toast(t('study.has.been.rejected.successfully'), { className: 'toast-success' });
            handleClose();
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.errorMessage) setServerError(data.errorMessage);
        })
        .catch(() => {
          setServerError('server.error');
        })
        .finally(() => setDisabledButtons(false));
    } catch (e) {
      setServerError('server.error');
      setDisabledButtons(false);
    }
  };

  const patientName = study && study.medicalCase && study.medicalCase.patient
    && `${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`;

  return (!!study
    && (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{t('study.confirm.dicom.rejection')}</DialogTitle>
      <DialogContent>
        <AlertUI severity="warning" title={t('warning')}>{t('study.reject.confirmation.message')}</AlertUI>
        <Margin mx-0 mt-0>
          <Column>
            <Span>
              <GreyLabel>{`${'StudyInstanceUID'}: `}</GreyLabel>
              <ExtraBold>{study.studyInstanceUid}</ExtraBold>
            </Span>
            {patientName && (
              <>
                <Span>
                  <GreyLabel>
                    {`${t('from.case')}: `}
                    <strong>{study.medicalCase.title}</strong>
                  </GreyLabel>
                </Span>
                <Span>
                  <GreyLabel>
                    {`${t('belonging.to.patient')}: `}
                    <strong>{patientName}</strong>
                  </GreyLabel>
                </Span>
              </>
            )}
          </Column>
        </Margin>
        {disabledButtons && <Loading />}
        {serverError && <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          disabled={disabledButtons}
          color="primary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          disabled={disabledButtons}
          color="primary"
          onClick={handleSubmit}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
    )
  );
};
