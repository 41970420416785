import React, { forwardRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { Span } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import { DELETE_STUDY_REPORT } from '../../../graphql/mutations';
import { GET_STUDY } from '../../../graphql/queries';
import { getFormattedDateTime } from '../../../utils/dateTimeUtils';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteReportDialog = ({ open, onClose, report, study }) => {
  if (!study) return null;
  if (!report) return null;

  const { t } = useTranslation();
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);

  const [deleteReport, { loading, called }] = useMutation(DELETE_STUDY_REPORT, {
    onCompleted() {
      setDisabledButtons(false);
      onClose();
    },
    refetchQueries: [{
      query: GET_STUDY,
      variables: {
        uuid: study.uuid,
      },
    }],
  });

  const handleSubmit = async () => {
    setServerError(false);
    setDisabledButtons(true);
    try {
      await deleteReport({ variables: { uuid: report.uuid } });
    } catch (errors) {
      if (errors.graphQLErrors && errors.graphQLErrors.length) setServerError(errors.graphQLErrors[0].message);
    }
  };

  const patientName = study.medicalCase && study.medicalCase.patient
    && `${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`;

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('report.confirm.delete')}</DialogTitle>
      <DialogContent>
        {(loading || called) ? (
          <Loading />
        ) : (
          <>
            <DialogContentText>
              {t('report.delete.confirmation.message')}
            </DialogContentText>
            <Margin mx-0 mt-0>
              <Column>
                <Span>
                  <GreyLabel>ID: </GreyLabel>
                  <strong>{report.uuid}</strong>
                </Span>
                <Span>
                  <GreyLabel>{`${t('advanced.tool')}: `}</GreyLabel>
                  <strong>{report.tool}</strong>
                </Span>
                <Span>
                  <GreyLabel>
                    {`${t('created.on')}: `}
                    <strong>{getFormattedDateTime({ date: report.date })}</strong>
                  </GreyLabel>
                </Span>
                {patientName && (
                  <Span>
                    <GreyLabel>
                      {`${t('belonging.to.patient')}: `}
                      <strong>{patientName}</strong>
                    </GreyLabel>
                  </Span>
                )}
              </Column>
            </Margin>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {serverError ? <AlertUI severity="error" title="Error">{serverError}</AlertUI> : null}
        <Button className={classes.button} disabled={disabledButtons} color="primary" onClick={onClose}>{t('cancel')}</Button>
        <Button className={classes.button} disabled={disabledButtons} color="primary" onClick={handleSubmit}>{t('delete')}</Button>
      </DialogActions>
    </Dialog>
  );
};
