import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import { navigate } from 'gatsby';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { ExtraBold, Span } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import { DELETE_DICOM_STUDY } from '../../../graphql/mutations';
import {
  GET_HOSPITAL,
  GET_MEDICAL_CASE_FOR_EDITING,
  GET_PATIENT_RELATED_DATA,
} from '../../../graphql/queries';
import useStyles from '../styled/DeleteStudyDialogStyles';
import { AlertUI } from '../../../componentsUI/Alert';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { isEmpty } from '../../../utils/ObjectUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const DeleteStudyDialog = ({ open, onClose, refetch, study, medicalCase = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState(false);

  const hospital = study && study.hospital;

  const refetchQueries = [
    ...hospital ? [{
      query: GET_HOSPITAL,
      variables: { uuid: hospital.uuid },
    }] : [],
    ...!isEmpty(medicalCase) ? [{
      query: GET_MEDICAL_CASE_FOR_EDITING,
      variables: { uuid: medicalCase.uuid },
    }] : [],
    ...!isEmpty(medicalCase) && medicalCase.patient ? [{
      query: GET_PATIENT_RELATED_DATA,
      variables: { uuid: medicalCase.patient.uuid },
    }] : [],
  ];

  const [deleteDicomStudy, { loading }] = useMutation(DELETE_DICOM_STUDY, {
    onCompleted() {
      onClose();
      if (refetch) {
        refetch();
        return;
      }
      if (medicalCase) {
        navigate(`/case/${study.medicalCase.uuid}`);
        return;
      }
      navigate('/studies/');
    },
    refetchQueries,
  });

  const handleSubmit = async () => {
    setServerError(false);
    setDisabledButtons(true);
    try {
      await deleteDicomStudy({ variables: { uuid: study.uuid } });
      return;
    } catch (error) {
      const errorMessage = networkErrorParse(error);
      if (errorMessage) setServerError(errorMessage);
    }
    setDisabledButtons(false);
  };

  const patientName = study && study.medicalCase && study.medicalCase.patient
    && `${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`;

  return (!!study
    && (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('study.confirm.delete')}</DialogTitle>
      <DialogContent>

        <DialogContentText>
          {t('study.delete.confirmation.message')}
        </DialogContentText>
        <Margin mx-0 mt-0>
          <Column>
            <Span>
              <GreyLabel>{`${t('study.title')}: `}</GreyLabel>
              <ExtraBold>{study.title}</ExtraBold>
            </Span>
            {patientName && (
              <>
                <Span>
                  <GreyLabel>
                    {`${t('from.case')}: `}
                    <strong>{study.medicalCase.title}</strong>
                  </GreyLabel>
                </Span>
                <Span>
                  <GreyLabel>
                    {`${t('belonging.to.patient')}: `}
                    <strong>{patientName}</strong>
                  </GreyLabel>
                </Span>
              </>
            )}
          </Column>
        </Margin>
        {disabledButtons && loading && <Loading />}
        {serverError && <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>}

      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          disabled={disabledButtons}
          color="primary"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          disabled={disabledButtons}
          color="primary"
          onClick={handleSubmit}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
    )
  );
};
