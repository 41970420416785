import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { toast } from 'react-toastify';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';

import { GreyLabel } from '../../Common/styled/GreyLabel';
import { UserInfo, UserDetails } from '../../Common/ShareCard/styled/ShareCardStyles';
import { HighlightedText } from '../../Common/styled/HighlightedText';

import { GET_STUDY } from '../../../graphql/queries';
import { DELETE_STUDY_COLLABORATION, DELETE_STUDY_GROUP_COLLABORATION } from '../../../graphql/mutations';
import { AvatarGroupIcon, AvatarUI } from '../../../componentsUI/Avatar';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 550,
      padding: 15,
    },
  },
  content: {
    overflow: 'hidden',
  },
  button: {
    margin: '0 6px',
    padding: '8px 20px',
    fontWeight: 600,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  text: {
    marginBottom: '.75em',
  },
  submit: {
    minWidth: 200,
    float: 'right',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const DeleteStudyCollaborationDialog = ({ collaboration, dicomStudyUuid, open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line no-underscore-dangle
  const isGroup = collaboration.__typename === 'DicomStudyGroupCollaboration';

  const { uuid, user } = collaboration;
  const userName = user ? `${user.name} ${user.surname}` : null;
  const userEmail = user ? user.email : collaboration.userEmail;
  const userPermission = t(collaboration.permission.toLowerCase());

  const [deleteCollaboration] = useMutation(DELETE_STUDY_COLLABORATION, {
    onCompleted: () => {
      toast(t('study.collaboration.successfully.removed'), { className: 'toast-info' });
    },
    onError: (error) => {
      if (error.message.includes('You are not allowed to perform this action')) {
        toast(t('error.not.allowed'), { className: 'toast-error' });
      } else {
        toast(t('error.removing.collaboration'), { className: 'toast-error' });
      }
    },
    refetchQueries: [{ query: GET_STUDY, variables: { uuid: dicomStudyUuid } }],
    awaitRefetchQueries: true,
    optimisticResponse: {
      deleteDicomStudyCollaboration: uuid,
    },
    update: (cache) => {
      const dicomStudy = cache.readQuery({ query: GET_STUDY, variables: { uuid: dicomStudyUuid } });
      const { collaborations } = dicomStudy.dicomStudy;
      dicomStudy.collaborations = collaborations.filter((c) => (c.uuid !== uuid));
      cache.writeQuery({
        query: GET_STUDY,
        data: dicomStudy,
      });
    },
  });

  const [deleteGroupCollaboration] = useMutation(DELETE_STUDY_GROUP_COLLABORATION, {
    onCompleted: () => {
      toast(t('study.group.collaboration.successfully.removed'), { className: 'toast-info' });
    },
    onError: (error) => {
      if (error.message.includes('You are not allowed to perform this action')) {
        toast(t('error.not.allowed'), { className: 'toast-error' });
      } else {
        toast(t('error.removing.collaboration'), { className: 'toast-error' });
      }
    },
    refetchQueries: [{ query: GET_STUDY, variables: { uuid: dicomStudyUuid } }],
    awaitRefetchQueries: true,
  });

  const handleDelete = () => {
    onClose();
    if (isGroup) {
      deleteGroupCollaboration({ variables: { input: uuid } }).then();
    } else {
      deleteCollaboration({ variables: { input: uuid } }).then();
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('study.collaboration.confirm.remove')}</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.text}>{t('collaboration.remove.confirmation.message')}</Typography>
        <Box>
          <UserInfo>
            {isGroup ? (
              <>
                <AvatarGroupIcon size={42} />
                <UserDetails>
                  {collaboration.group.name}
                  <br />
                  <HighlightedText>{userPermission}</HighlightedText>
                </UserDetails>
              </>
            ) : (
              <>
                <AvatarUI size={42} user={user} />
                <UserDetails>
                  {userName}
                  {userName && <br />}
                  <GreyLabel>{userEmail}</GreyLabel>
                </UserDetails>
              </>
            )}
          </UserInfo>
        </Box>
        <DialogActions>
          <Button
            variant="outlined"
            className={classes.button}
            color="primary"
            onClick={onClose}
          >
            {t('return')}
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={handleDelete}
          >
            {t('remove')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
