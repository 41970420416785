import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import moment from 'moment-timezone';

import { Box, Chip, Grid, Paper } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { getLocaleYears } from '../../utils/dateTimeUtils';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { LinkTextButton } from '../Common/styled/TextButton';
import { InnerSpanHTML } from '../Common/InnerHTML';
import useStyles from './styled/StudyMakeStyles';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import UserInfo from '../../componentsUI/UserInfo';
import { FIND_USER_PROFILE_BY_UUID } from '../../graphql/queries';

const { appDoctor } = useApplicationInterface();

export const StudyDetailsInfoCard = ({ study }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const age = study.medicalCase && getLocaleYears(moment(study.medicalCase.patient.birthDate));

  const { data } = useQuery(
    FIND_USER_PROFILE_BY_UUID,
    {
      variables: {
        uuid: study.owner,
      },
    },
  );

  const admin = data && data.userByUuid;

  let accessDetails = 'study.available.to.all.members';

  if (study.medicalCase !== null) {
    accessDetails = hospital && hospital.menuOptionMedicalCases
      ? 'study.associated.to.medical.case'
      : 'study.associated.to.patient';
  }
  if (study.restricted) {
    accessDetails = 'study.with.restricted.access';
  }

  return (
    <Paper elevation={2} className={classes.wrapper} style={{}}>
      <Box className={classes.box}>
        <Box className={classes.userInfo}>
          <div className={classes.adminInfo}>
            <Grid container>
              {admin && <UserInfo admin={admin} entity={study} type="study" />}
            </Grid>
          </div>
        </Box>
        <Grid container justifyContent="flex-start" alignItems="stretch">
          {appDoctor && (
            <Grid item xs={12}>
              <div className={classes.patientInfo}>
                {study.medicalCase ? (
                  <>
                    <GreyLabel>{`${t('patient')}: `}</GreyLabel>
                    <Link to={`/patient/${study.medicalCase.patient.uuid}`}>
                      <LinkTextButton>
                        {`${study.medicalCase.patient.name} ${study.medicalCase.patient.surname}`}
                      </LinkTextButton>
                    </Link>
                    <strong>{age}</strong>
                  </>
                ) : (
                  <GreyLabel>{t('hospital.study')}</GreyLabel>
                )}
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={10} className={classes.indent}>
            <div className={classes.caseTitle}>{study.title}</div>
            {appDoctor && (
              <Box className={classes.chip}>
                <Chip label={t(accessDetails)} color="primary" />
              </Box>
            )}
            <Box className={classes.description}>
              <InnerSpanHTML content={study.description} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default StudyDetailsInfoCard;
