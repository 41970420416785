import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Grid } from '@material-ui/core';
import { CloudDownload, Save, Web } from '@material-ui/icons';

import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { webViewerEndpoints } from '../../../config';
import { StudyDetailsWrapper } from './styled/StyledStudyDetails';
import { MainPageContent } from '../Common/styled/PageWithSidebar';
import { Margin } from '../Common/styled/Margins';
import { Column, Row } from '../Common/styled/Groups';
import { Span } from '../Common/styled/Text';
import { StudyDetailsDicomCard } from './StudyDetailsDicomCard';
import { StudyDetailsInfoCard } from './StudyDetailsInfoCard';
import { StudyDetailsReportsCard } from './StudyDetailsReportsCard';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { ByUser } from '../Common/User';
import useStyles from './styled/StudyMakeStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { PageContent } from '../Common/styled/PageContent';
import { getStudyApplicationPath } from './utils/StudyApplicationPath';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import Loading from '../Common/Loading';

const { appDoctor } = useApplicationInterface();

export const StudyPatientDetails = ({ study, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);

  const hospital = study && (study.hospital
    ? study.hospital
    : study.medicalCase && study.medicalCase.patient.hospital);

  const hospitalUuid = hospital && hospital.uuid;

  if (hospitalUuid && currentHospital && currentHospital.uuid !== hospitalUuid) {
    setHospitalEnvironment({ uuid: hospitalUuid, dispatch, context });
  }

  const isBrowser = () => typeof window !== 'undefined';

  const openInWebViewer = () => {
    const url = `${webViewerEndpoints.getStudy}${study.studyInstanceUid}`;
    if (isBrowser) window.open(url, '_blank');
  };

  const downloadStudy = async () => {
    dispatch({ type: 'ADD_STUDY_DOWNLOAD', download: { uuid: study.uuid, type: 'study', name: study.title } });
  };

  const downloadStudyCD = async () => {
    dispatch({ type: 'ADD_STUDY_DOWNLOAD', download: { uuid: study.uuid, type: 'cd', name: study.title } });
  };

  const cdLiteButton = hospital && hospital.enableCdLiteDownload ? [
    { name: 'download.cd.lite', icon: Save, handleClick: downloadStudyCD },
  ] : [];

  const buttons = [
    { name: 'open.in.viewer', icon: Web, handleClick: openInWebViewer },
    { name: 'divider2', type: 'divider' },
    ...cdLiteButton,
    { name: 'download.study', icon: CloudDownload, handleClick: downloadStudy },
  ];

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  if (!study) return <Loading />;

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="study.info" items={buttons} breadcrumbs={getStudyApplicationPath(study)} />
      </Navbar>
      <Container maxWidth="lg">
        <ScrollableContainer padding="1.25em 0 0">
          <PageContent>
            {study && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <StudyDetailsInfoCard study={study} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StudyDetailsDicomCard study={study} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <StudyDetailsReportsCard study={study} />
                  </Grid>
                </Grid>
              </>
            )}
            {appDoctor && (
              <StudyDetailsWrapper>
                <MainPageContent>
                  <Margin mt-0 mx-0>
                    <Row>
                      <Column>
                        <Span>
                          <GreyLabel>{`${t('study.uploaded.managed.by')}: `}</GreyLabel>
                          <ByUser user={study.owner} />
                        </Span>
                      </Column>
                    </Row>
                  </Margin>
                </MainPageContent>
              </StudyDetailsWrapper>
            )}
          </PageContent>
        </ScrollableContainer>
      </Container>
    </>
  );
};

export default StudyPatientDetails;
