import React from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useStyles } from './styles/SectionAccordion';

const SectionAccordion = ({ Item, Header, title, expanded }) => {
  const classes = useStyles();

  return (
    <Accordion
      defaultExpanded={expanded}
      classes={{
        root: classes.root,
        summary: classes.accordion,
      }}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMore />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        {!Header
          ? <Typography className={classes.title}>{title}</Typography>
          : <Header />}
      </AccordionSummary>
      <AccordionDetails auto="true" className={classes.listcards}>
        <Item className={classes.card} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionAccordion;
