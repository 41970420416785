import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { Layout } from '../../components/MenuLayout';
import { StudyDetails } from '../../components/Study/StudyDetails';
import Loading from '../../components/Common/Loading';
import { GET_STUDY } from '../../graphql/queries';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import { StudyPatientDetails } from '../../components/Study/StudyPatientDetails';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export default ({ studyId }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const { loading, data, error, refetch } = useQuery(
    GET_STUDY,
    {
      variables: { uuid: studyId },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (!errorMessage && error) setErrorMessage(networkErrorParse(error));

  const { patientApp } = useApplicationInterface();
  const StudyView = patientApp ? StudyPatientDetails : StudyDetails;
  const study = data && data.dicomStudy;
  const renderStudy = study || errorMessage;

  return (
    <Layout>
      {loading && !renderStudy && <Loading />}
      {renderStudy && <StudyView study={study} refetch={refetch} errorMessage={errorMessage} />}
    </Layout>
  );
};
