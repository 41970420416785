import styled from 'styled-components';

import { sizes } from '../../../styles/variables';
import { Button } from './Button';

export const MainPageContent = styled.section`
  width: 80%;

  @media (max-width: ${sizes.mdMax}) {
    width: 100%;
  }
`;

export const PageSidebar = styled.aside`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.mdMax}) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;

    ${Button} {
      width: auto;
    }

    & > :not(:first-child) {
      margin-left: 1%;
    }
  }
`;
