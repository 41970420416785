import React from 'react';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import defaultPhoto from '../../../images/alma-default.jpg';

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: '.9375em',
    fontWeight: 700,
    margin: '.0625em .5em',
  },
  provider: {
    fontSize: '.875em',
    color: theme.palette.grey.A200,
    margin: '0 .625em',
  },
  image: {
    width: 50,
    '& img': {
      maxHeight: 40,
      width: '100%',
      maxWidth: '100%',
      objectFit: 'cover',
    },
  },
}));

export default ({ advancedTool }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs="auto">
        <Box className={classes.image}>
          <img src={advancedTool.provider.logo || defaultPhoto} alt="Advanced Tool" />
        </Box>
      </Grid>
      <Grid item xs>
        <p className={classes.name}>
          {advancedTool.name}
        </p>
        <p className={classes.provider}>
          {advancedTool.provider.name}
        </p>
      </Grid>
    </Grid>
  );
};
