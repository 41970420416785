import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useMutation } from 'react-apollo';
import * as Yup from 'yup';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Backspace } from '@material-ui/icons';

import { GreyLabel } from '../Common/styled/GreyLabel';
import { SharePermissionsDropdown } from './SharePermissionsDropdown';
import { IconButtonUI } from '../../componentsUI/IconButton';
import { SET_STUDY_COLLABORATION_PERMISSION, SET_STUDY_GROUP_COLLABORATION_PERMISSION } from '../../graphql/mutations';
import { Form } from '../Common/styled/Form';
import { DeleteStudyCollaborationDialog } from './modal/DeleteStudyCollaborationDialog';
import {
  ShareDetailsButtonWrapper,
  ShareDetailsContainer,
  ShareDetailsOptionsItem,
  ShareDetailsOptionsWrapper,
  ShareDetailsUserItem,
  ShareUserInfoCard,
} from './ShareDetailsContainer';
import { AvatarGroupIcon, AvatarUI } from '../../componentsUI/Avatar';

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    alignSelf: 'stretch',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  shareItemLabel: {
    marginBottom: 8,
  },
  shareItemPaper: {
    margin: '4px 12px',
    border: '1px solid #ddd',
    borderRadius: 4,
    boxShadow: '00px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    '@media (max-width:991px)': {
      margin: '4px 6px',
    },
    '@media (max-width:599px)': {
      margin: 4,
    },
  },
  userEmail: {
    color: theme.palette.primary.light,
    fontSize: '.9375em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const ManageStudyCollaborationPermissions = ({ collaborations, dicomStudyUuid }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [selectedCollaboration, setSelectedCollaboration] = useState();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const [setCollaborationPermission] = useMutation(SET_STUDY_COLLABORATION_PERMISSION, {
    onCompleted: ({ setDicomStudyCollaborationPermission: data }) => {
      const successMessage = data.user
        ? t(
          'collaboration.successfully.updated.user',
          { user: data.user, permission: data.permission },
        )
        : t(
          'collaboration.successfully.updated.email',
          { email: data.userEmail, permission: data.permission },
        );
      toast(successMessage, { className: 'toast-success' });
    },
    onError: () => {
      toast(t('error.updating.collaboration'), { className: 'toast-error' });
    },
  });

  const [setGroupCollaborationPermission] = useMutation(SET_STUDY_GROUP_COLLABORATION_PERMISSION, {
    onCompleted: ({ setDicomStudyGroupCollaborationPermission: data }) => {
      const successMessage = t(
        'group.collaboration.successfully.updated',
        { groupName: data.group.name, permission: data.permission },
      );
      toast(successMessage, { className: 'toast-success' });
    },
    onError: () => {
      toast(t('error.updating.collaboration'), { className: 'toast-error' });
    },
  });

  const updatePermission = (permission, uuid, isGroup) => {
    if (isGroup) {
      const setGroupCollaborationPermissionInput = {
        permission: permission.toUpperCase(),
        dicomStudyGroupCollaborationUuid: uuid,
      };

      setGroupCollaborationPermission({
        variables: { input: setGroupCollaborationPermissionInput },
      });
    } else {
      const setCollaborationPermissionInput = {
        permission: permission.toUpperCase(),
        dicomStudyCollaborationUuid: uuid,
      };

      setCollaborationPermission({
        variables: { input: setCollaborationPermissionInput },
      });
    }
  };

  const removeCollaboration = (collaboration) => {
    if (!collaboration) {
      toast(t('error.removing.collaboration'), { className: 'toast-error' });
    }
    setSelectedCollaboration(collaboration);
    setShowModal(true);
  };

  const validationSchema = Yup.object({
    permission: Yup.mixed().oneOf(['view', 'edit']),
  });

  const createShareCards = () => collaborations.map((collaboration) => {
    // eslint-disable-next-line no-underscore-dangle
    const isGroup = collaboration.__typename === 'DicomStudyGroupCollaboration';
    const { user, uuid } = collaboration;
    const userName = user ? `${user.name} ${user.surname}` : null;
    const userEmail = user ? user.email : collaboration.userEmail;
    const shareOptions = [
      { value: 'view', label: 'view' },
      { value: 'edit', label: 'edit' },
    ];

    return (
      <Box key={uuid} className={styles.shareItemPaper}>
        <ShareDetailsContainer>
          <ShareDetailsUserItem>
            {isGroup ? (
              <>
                <AvatarGroupIcon size={42} />
                <ShareUserInfoCard>
                  {collaboration.group.name}
                </ShareUserInfoCard>
              </>
            ) : (
              <>
                <AvatarUI size={42} user={user} />
                <ShareUserInfoCard>
                  {userName}
                  {userName && <br />}
                  <Typography className={styles.userEmail}>{userEmail}</Typography>
                </ShareUserInfoCard>
              </>
            )}
          </ShareDetailsUserItem>
          <ShareDetailsOptionsWrapper>
            <ShareDetailsOptionsItem>
              <Formik
                initialValues={{ permission: collaboration.permission.toLowerCase() }}
                validationSchema={validationSchema}
                onSubmit={() => {}}
              >
                {(props) => (
                  <Form>
                    <SharePermissionsDropdown
                      form={props}
                      label={t('permission.type')}
                      options={shareOptions}
                      update={(v) => updatePermission(
                        v.permission,
                        collaboration.uuid,
                        isGroup,
                      )}
                    />
                  </Form>
                )}
              </Formik>
            </ShareDetailsOptionsItem>
            <ShareDetailsButtonWrapper>
              <IconButtonUI
                Icon={Backspace}
                title={t('remove.permission')}
                selected={false}
                handleClick={() => removeCollaboration(collaboration)}
              />
            </ShareDetailsButtonWrapper>
          </ShareDetailsOptionsWrapper>
        </ShareDetailsContainer>
      </Box>
    );
  });

  return (
    <>
      <Box className={styles.shareItemLabel}>
        <GreyLabel>{t('manage.permissions')}</GreyLabel>
      </Box>
      <Box className={styles.content}>
        <Grid container className="user-cards">
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {collaborations && createShareCards()}
              </Grid>
            </Grid>
            {showModal && (
              <DeleteStudyCollaborationDialog
                collaboration={selectedCollaboration}
                dicomStudyUuid={dicomStudyUuid}
                onClose={closeModal}
                open={showModal}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
