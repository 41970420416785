import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  summary: {
    padding: 8,
  },
  root: {
    margin: '1px 0',
    padding: '4px 18px',
    background: '#FFFFFFC0',
    boxShadow: '2px 4px 7px #00000020',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '1px 0',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '48px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '1.25em',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 0.25,
    flex: 'auto',
  },
  listcards: {
    display: 'block',
    padding: '2px 0px 0px',
  },
  card: {
    marginBottom: 16,
    borderRadius: 5,
    boxShadow: '1px 3px 7px 0 #00000020',
    backgroundColor: '#fff',
    padding: '19px 24px',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: 1,
  },
});
