export const advancedToolCredentials = {
  KoiosDs: { username: 'almademo', password: 'AlmaDemoTeam21!' },
};

export const UI_TYPE_BACKEND = 0;
export const UI_TYPE_BRIDGE = 1;
export const UI_TYPE_WINDOW = 2;
export const UI_TYPE_AE_TITLE = 3;

export const UI_TOOL_STATUS_DEFAULT = 'default';
export const UI_TOOL_STATUS_CHECKING = 'checking';
export const UI_TOOL_STATUS_READY = 'ready';
export const UI_TOOL_STATUS_ACTIVE = 'active';

const advancedToolStatusList = [
  { id: 1, value: 'ERROR', label: 'error' },
  { id: 2, value: 'PENDING', label: 'pending' },
  { id: 3, value: 'OPEN_IFRAME', label: 'requested' },
  { id: 4, value: 'COMPLETED', label: 'completed' },
];

export const advancedToolInterfaceType = [
  { id: 1, value: '0', label: 'backend.api' },
  { id: 2, value: '3', label: 'dicom.ae.title' },
  { id: 3, value: '2', label: 'open.window' },
  { id: 4, value: '1', label: 'login.with.iframe' },
];

export const getAdvancedToolStatusLabel = (value) => {
  const status = advancedToolStatusList.find((item) => item.value === value);
  if (!status) return null;
  return status.label;
};
