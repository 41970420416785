import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from '../../Common/Loading';
import { canI } from '../../../casl/Can';
import { isEmpty } from '../../../utils/ObjectUtils';
import { GET_STUDY } from '../../../graphql/queries';
import { EDIT_DICOM_STUDY_INFO } from '../../../graphql/mutations';
import { StudyInfoForm } from '../StudyInfoForm';
import { AlertUI } from '../../../componentsUI/Alert';
import { graphQLErrorParse, networkErrorParse } from '../../../utils/ErrorGraphQLUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
      '@media (max-width:600px)': {
        padding: 0,
        margin: '.75em',
      },
      '& > div > h2': {
        fontWeight: 600,
        lineHeight: 1.3,
      },
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  submit: {
    minWidth: 200,
    float: 'right',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const EditStudyDialog = ({ study, open, onClose }) => {
  if (!study) return null;

  const { t } = useTranslation();
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);

  const { loading, data, refetch } = useQuery(
    GET_STUDY,
    {
      variables: {
        uuid: study.uuid,
      },
    },
  );

  const [updateDicomStudy, { loading: updating }] = useMutation(EDIT_DICOM_STUDY_INFO, {
    onCompleted() {
      refetch().then();
      onClose();
    },
    refetchQueries: [{
      query: GET_STUDY,
      variables: {
        uuid: study.uuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }],
    onError: (error) => {
      if (graphQLErrorParse(error)) {
        setServerError(graphQLErrorParse(error));
        return;
      }
      setServerError(networkErrorParse(error));
    },
  });

  const goBack = () => window.history.back();

  const submitAction = async (values) => {
    updateDicomStudy({
      variables: {
        dicomStudy: {
          uuid: study.uuid,
          title: values.title,
          description: values.description,
          restricted: values.restricted === 'true' || values.restricted === true,
        },
      },
    }).then();
  };

  const initialValues = data && data.dicomStudy && {
    title: data.dicomStudy.title,
    description: data.dicomStudy.description,
    restricted: data.dicomStudy.restricted,
  };

  const isHospitalStudy = data && data.dicomStudy && data.dicomStudy.medicalCase === null;
  const isUserStudyManager = data && canI('delete', data.dicomStudy);

  const showServerError = () => {
    const message = serverError.includes('you.are.not.allowed.to.perform.this.action')
      ? t('not.allowed.to.perform.action')
      : t('server.error');
    return <AlertUI severity="error" title="Error">{message}</AlertUI>;
  };

  const buttons = [
    { classes: classes.button, color: 'primary', variant: 'outlined', onClick: onClose, label: t('cancel') },
    { classes: classes.button, color: 'primary', variant: 'contained', type: 'submit', form: 'study-info-form', label: t('update') },
  ];

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('edit.study.info')}</DialogTitle>
      <DialogContent>
        {(loading || isEmpty(data)) ? (
          <Loading />
        ) : (
          <StudyInfoForm
            formId="study-info-form"
            initialValues={initialValues}
            isHospitalStudy={isHospitalStudy}
            buttons={buttons}
            submitAction={submitAction}
            serverError={serverError}
            setServerError={setServerError}
            submitting={updating}
            onClose={goBack}
            showServerError={showServerError}
            manageRestriction={isUserStudyManager}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
